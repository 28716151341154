import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Notebox({data}) {
    return (
        <div className="p-100 pb-0">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="boxes_item text-center">
                            <Row>
                                <Col xs={12} md={10} lg={8} xl={8} xxl={8} className="m-auto">
                                    <h2>{HtmlParser(checkData(data, 'note_box_title'))}</h2>
                                    <div>{HtmlParser(checkData(data, 'note_box_description'))}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default Notebox;